import React from "react";
import { connect } from "react-redux";

class Translate extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
    this.filter = this.filter.bind(this);
  }

  public filter(itemKey: string) {
    return this.props.langitems[itemKey];
  }

  public render() {
    let css = "";
    if (typeof this.props.classes !== "undefined" && this.props.classes.length > 0) {
      css += this.props.classes.join(" ");
    } else {
      css = "trans";
    }

    return <span className={css} dangerouslySetInnerHTML={{ __html: this.filter(this.props.id) }} />;

  }
}

export default connect((state: any) => {
  return {
    langitems: state.i18n.translations,
    langcode: state.i18n.lang
  };
})(Translate);

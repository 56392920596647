import React, { ReactElement, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import classNames from "classnames";
import { Form, actions, Control, Errors } from "../lib/react-redux-form";

import Preferences from "./preferences";
import Translate from "../translate";
import Settings from "../settings";
import * as MENUITEMS from "../sidebar/menu.json";
import { useNavigate } from "@reach/router";

/*
//     ath: state.ui.ath,
//     mode: state.ui.mode,
//     areaMetric: state.pages.Location.areaMetric,
//     sbright: state.ui.sbright,
//     translation: state.i18n,
//     entrans: state.data.langcache,
*/

export default function Header(props: any) {
  const [prefClasses, setPrefClasses] = useState("hide");

  const dispatch = useDispatch();
  const { ath, mode, sbright } = useSelector((state: any) => state.ui);
  const translation = useSelector((state: any) => state.translation);
  const navigate = useNavigate();

  const loadLanguage = (codeCountry = "en") => {
    // Fetch translation data
    const url = Settings.getLocaleURLFor(codeCountry);
    // eslint-disable-next-line
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        // Overlay the english translations
        const transObj = {
          lang: codeCountry,
          translations: {
            ...props.entrans.translations,
            ...json.translations,
          },
        };
        // props.setTranslationLanguage(transObj);
        dispatch({ type: "SET_UITRANSLATION", object: transObj });
      });
  };

  const getStatus = (activeItem: number, pos = 1): any => {
    let elements = null;

    const active = activeItem === pos;

    if (activeItem >= pos) {
      elements = (
        <i
          className={classNames("material-icons", "md-18", { active: active === true, last: activeItem === pos })}
          style={{ fontSize: "16px", padding: "7px 0px 0px 10px", float: "right" }}
        >
          done
        </i>
      );
    }

    if (activeItem === -1) {
      elements = (
        <i
          className={classNames("material-icons", "md-18", { active: active === true })}
          style={{ fontSize: "16px", padding: "7px 0px 0px 10px", float: "right" }}
        >
          radio_button_unchecked
        </i>
      );
    }

    return elements;
  };

  const dropStuff = () => {
    window.localStorage.removeItem("root");
    navigate("/");
    alert("State nuked.");
  };

  const onClick = (e: any) => {
    switch (e.currentTarget.name) {
      case "togglePrefs": {
        if (prefClasses === "hidden" || prefClasses === "hide") {
          setPrefClasses("show");
        } else {
          setPrefClasses("hide");
        }
        break;
      }
      case "toggleUnit": {
        //this.props.dispatch(actions.change("pages.Location.areaMetric", this.props.areaMetric === "m2" ? "ft" : "m2"));
        break;
      }
      case "displayState": {
        //this.props.setRightSidebar({ sbright: !this.props.sbright });
        break;
      }
      case "en": {
        loadLanguage("en");
        break;
      }
      case "de": {
        loadLanguage("de");
        break;
      }
      case "pl": {
        loadLanguage("pl");
        break;
      }
      case "fr": {
        loadLanguage("fr");
        break;
      }
      case "dk": {
        loadLanguage("dk");
        break;
      }
      case "es": {
        loadLanguage("es");
        break;
      }
      default: {
        // NOP
      }
    }
  };
  const activeItem: any = 1;
  // if (this.props.ath !== undefined) {
  //   activeItem = this.props.ath;
  // }
  let modeFragment = null;
  if (mode === "assisted") {
    modeFragment = (
      <span className="rcmode">
        <Translate id="app.generic.mode.assisted" />
      </span>
    );
  }

  if (mode === "freehand") {
    modeFragment = (
      <span className="rcmode">
        <Translate id="app.generic.mode.freehand" />
      </span>
    );
  }

  const mode1 = (
    <span className="rcmode">
      <Translate id="app.generic.mode.freehand" />
    </span>
  );

  return (
    <div id="header">
      {<Preferences classes={prefClasses} />}
      <div className="navbar col-12">
        <div className="col-6">
          <button
            className="btn btn-secondary btn-sm btnTogglePrefs"
            name="togglePrefs"
            type="button"
            aria-label="Toggle navigation"
            onClick={onClick}
          >
            <span className="btnlabel">
              <Translate id="app.generic.btn.languageselection" />
            </span>
          </button>
          {process.env.NODE_ENV !== "production" && (
            <button type="button" onClick={dropStuff} className="btn btn-secondary btn-sm btnTogglePrefs">
              Nuke
            </button>
          )}
        </div>
        <div className="col-4 text-right ci">
          <Translate id="header.ci" />
          {modeFragment}
        </div>
      </div>
      <div className="status" id="statusheader">
        <ul id="statusdisplay">
          <li id="si01" className={classNames({ active: activeItem === 0 })}>
            <Translate id="header.label.tab1" /> {getStatus(activeItem, 0)}
          </li>
          <li
            id="si02"
            className={classNames({
              active: activeItem >= 1,
              last: activeItem === 1,
            })}
          >
            <Translate id="header.label.tab2" /> {getStatus(activeItem, 1)}
          </li>
          <li
            id="si03"
            className={classNames({
              active: activeItem >= 2,
              last: activeItem === 2,
            })}
          >
            <Translate id="header.label.tab3" /> {getStatus(activeItem, 2)}
          </li>
          <li
            id="si04"
            className={classNames({
              active: activeItem >= 3,
              last: activeItem === 3,
            })}
          >
            <Translate id="header.label.tab4" /> {getStatus(activeItem, 3)}
          </li>
          <li
            id="si05"
            className={classNames({
              active: activeItem >= 4,
              last: activeItem === 4,
            })}
          >
            <Translate id="header.label.tab5" /> {getStatus(activeItem, 4)}
          </li>
        </ul>
      </div>
    </div>
  );
}

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Root from "./root/root";
import Modal from "react-modal";

// Styles
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Loading } from "./comps/loading";

// Create a client
const queryClient = new QueryClient();

export const StoreContext = React.createContext({});

// ////////////////////////////////////////////////////////////////////////////////////////
// App / Main Shell
// ////////////////////////////////////////////////////////////////////////////////////////

Modal.setAppElement("#app");

export default function App(props: any) {
  return (
    <Provider store={props.store}>
      <PersistGate loading={<Loading />} persistor={props.persistor}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <StoreContext.Provider value={{ persistor: props.persistor }}>
              <Root />
            </StoreContext.Provider>
          </BrowserRouter>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

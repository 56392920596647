import Axios, { AxiosPromise } from "axios";

export default class Settings {
  public static baseURL(): string {
    return (process.env.REACT_APP_BASE_URL + "/") as string;
  }

  public static getBuildingThumbnail(codeCountry: string, codeBuilding: string) {
    return `https://s2.building-typology.eu/img/matrix/${codeCountry}/${codeBuilding}.thumb.png`;
  }

  /**
   * Return Lodash Template Interpolation settings: templateSettings.interpolate = Settings.getTemplateSettings();
   */
  public static getTemplateSettings(): RegExp {
    return /\{(.+?)\}/g;
  }

  /**
   * Path to Spring Boot Data service
   */
  public static dataBaseURL(): string {
    return (process.env.REACT_APP_SPB_HOST + "/") as string;
  }

  /**
   * Path to Hasura GQL service
   */
  public static getGQLEndPointURL(): string {
    return process.env.REACT_APP_GQL_HOST as string;
  }

  public static getRESTEndpointURL(): string {
    return process.env.REACT_APP_REST_HOST as string;
  }

  public static dynDataURL(urlFragment = ""): string {
    return `${Settings.dataBaseURL()}${urlFragment}`;
  }

  public static dataURL(): string {
    return `${Settings.baseURL()}data/`;
  }

  public static getDataURLFor(data: string): string {
    return `${Settings.dataURL()}${data}`;
  }

  public static getCbxData(country = "en", filename: string): string {
    country = country.toLowerCase();
    return `${Settings.baseURL()}locale/cbx/${country}/${filename}`;
  }

  public static assetURL(): string {
    return process.env.REACT_APP_ASSETS_URL as string;
  }

  public static localeURL(): string {
    return `${Settings.baseURL()}locale/`;
  }

  public static getAssetURLFor(asset: string): string {
    return `${Settings.assetURL()}${asset}`;
  }

  public static getLocaleURLFor(codeCountry = "en") {
    return `${Settings.localeURL()}locale_${codeCountry}.json`;
  }

  public static getAxios(url: string): AxiosPromise<any> {
    const ax = Axios.create().get(url);
    return ax;
  }


  /**
   * Return a div with a list of error messages
   * @param errorMessages Array with Error messages
   */
  public static formatErrors(errorMessages: string[]) {
    if (!errorMessages) {
      return null;
    }

    let errors = null;

    if (errorMessages.length > 0) {
      errors = errorMessages.map((msg: string, index: number) => {
        return <li key={index} dangerouslySetInnerHTML={{ __html: msg }} />;
      });
    } else {
      return null;
    }

    return (
      <div className="alert alert-warning" role="alert">
        <ul className="errorlist pd-2">{errors}</ul>
      </div>
    );
  }
}

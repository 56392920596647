import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../store";
import { Router, Location } from "@reach/router";
import Loadable from "react-loadable";
import { Sidebar } from "../sidebar";
import { Header } from "../header";
import { KBarProvider, KBarPortal, KBarPositioner, KBarAnimator, KBarSearch, useMatches, KBarResults } from "kbar";

const Loading = () => {
  return <div>Loading ....</div>;
};

const NoMatch = (props: any) => {
  return <div>Not Found</div>;
};

function RenderResults() {
  const { results } = useMatches();

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === "string" ? (
          <div
            style={{
              padding: "12px 16px",
              background: active ? "#199bf1" : "transparent",
              color: active ? "#fff" : "#555",
              borderLeft: `2px solid ${active ? "var(--foreground)" : "transparent"}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            {item}
          </div>
        ) : (
          <div
            style={{
              padding: "12px 16px",
              background: active ? "#199bf1" : "transparent",
              borderLeft: `2px solid ${active ? "var(--foreground)" : "transparent"}`,
              color: active ? "#fff" : "#555",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            {item.name}
          </div>
        )
      }
    />
  );
}

const searchStyle = {
  padding: "12px 16px",
  fontSize: "16px",
  width: "100%",
  boxSizing: "border-box",
  outline: "none",
  border: "1px solid #a0a0a0",
  borderLeft: "none",
  borderRight: "none",
  background: "#fefefe",
  color: "#000",
  zIndex: "20",
};

function getActions() {
  const actions = [
    {
      id: "sensitivity",
      name: "Sensitivity",
      shortcut: ["s"],
      keywords: "sensitivity",
      perform: () => {
        window.location = "/sensitivity/" as any;
      },
    },
    {
      id: "propertdesc",
      name: "Propertydesc",
      shortcut: ["p"],
      keywords: "property",
      perform: () => {
        window.location = "/assisted/propdesc/" as any;
      },
    },
    {
      id: "retrofitmeasure",
      name: "BundleOfRetrofitMeasures",
      shortcut: ["r"],
      keywords: "retrofit",
      perform: () => {
        window.location = "/assisted/rfmeasures/" as any;
      },
    },
    {
      id: "location",
      name: "Location",
      shortcut: ["l"],
      keywords: "location",
      perform: () => {
        window.location = "/location/" as any;
      },
    },
    {
      id: "invchars",
      name: "Investor Characteristics",
      shortcut: ["v"],
      keywords: "invchar, investor",
      perform: () => {
        window.location = "/invchars/" as any;
      },
    },
    {
      id: "enconsbefore",
      name: "Energy Consumption Before Refurbishment",
      shortcut: ["b"],
      keywords: "energy,refurbishment",
      perform: () => {
        window.location = "/combensconsbeforeref/calc/" as any;
      },
    },
    {
      id: "enconsafter",
      name: "Energy Consumption after Refurbishment",
      shortcut: ["b"],
      keywords: "energy,refurbishment",
      perform: () => {
        window.location = "/ecafterref/calc/" as any;
      },
    },
    {
      id: "fininfo",
      name: "Financial Information",
      shortcut: ["f"],
      keywords: "fininfo,financial",
      perform: () => {
        window.location = "/fininfo/" as any;
      },
    },
  ];

  return actions;
}

const animatorStyle = {
  maxWidth: "600px",
  width: "100%",
  background: "#fefefe",
  color: "#000",
  borderLeft: "1px solid #a0a0a0",
  borderRight: "1px solid #a0a0a0",
  borderBottom: "1px solid #a0a0a0",
  borderRadius: "0",
  overflow: "hidden",
  boxShadow: "var(--shadow)",
  zIndex: "20",
};

const groupNameStyle = {
  padding: "8px 16px",
  fontSize: "10px",
  color: "red",
  textTransform: "uppercase",
  opacity: 0.5,
};

function Main(props: any) {
  const actions = getActions();

  return (
    <KBarProvider actions={actions}>
      <KBarPortal>
        <KBarPositioner>
          <KBarAnimator style={animatorStyle}>
            <KBarSearch style={searchStyle as any} />
            <RenderResults />
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>
    </KBarProvider>
  );
}

class Root extends React.Component<any, any> {
  private Screen01 = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "screen01" */ "../forms/intro/screen01");
    },
    loading: Loading,
  });

  private Screen02 = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "screen02" */ "../forms/intro/screen02");
    },
    loading: Loading,
  });

  private Screen03 = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "screen03" */ "../forms/intro/screen03");
    },
    loading: Loading,
  });

  private Screen04 = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "screen04" */ "../forms/intro/screen04");
    },
    loading: Loading,
  });

  private Video = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "video" */ "../forms/intro/video");
    },
    loading: Loading,
  });

  private Selection = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "selection" */ "../forms/intro/selection");
    },
    loading: Loading,
  });

  private Signup = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "signup" */ "../forms/signup");
    },
    loading: Loading,
  });

  private Location = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "location" */ "../forms/location");
    },
    loading: Loading,
  });

  private Location2 = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "location2" */ "../forms/location/location2");
    },
    loading: Loading,
  });

  private InvestorCharacteristics = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "investorcharacteristics" */ "../forms/investorcharacteristics");
    },
    loading: Loading,
  });

  //   private InvestorCharacteristics2 = Loadable({
  //     loader: () => {
  //       return import(/* webpackChunkName: "investorcharacteristics2" */ "../forms/investorcharacteristics/invchars");
  //     },
  //     loading: Loading,
  //   });

  private PropertyDescription = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "propertydescription" */ "../forms/propertydesc");
    },
    loading: Loading,
  });

  private APropertyDesc = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "asmpropertydesc" */ "../forms/asmpropertydesc/asmpropertydesc.main");
    },
    loading: Loading,
  });

  // private InvCostsEnergeticRef = Loadable({
  //   loader: () => {
  //     return import(/* webpackChunkName: "invcostsenref" */ "../forms/invcostsenref");
  //   },
  //   loading: Loading,
  // });

  private InvCostsEnergeticRef = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "invcostsenref" */ "../forms/invcostsenref/invcostsenref.main");
    },
    loading: Loading,
  });

  private ChangeAnnualMaintenanceAfterRef = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "channualmaintafterref" */ "../forms/channualmaintafterref");
    },
    loading: Loading,
  });

  private Depreciation = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "depreciation" */ "../forms/depreciation");
    },
    loading: Loading,
  });

  //   private AnEnergyCostsBeforeRef = Loadable({
  //     loader: () => {
  //       return import(/* webpackChunkName: "anenergycostsbeforeref" */ "../forms/anenergycostsbeforeref");
  //     },
  //     loading: Loading,
  //   });

  //
  private AsmAnEnergyCostsBeforeRef = Loadable({
    loader: () => {
      return import(
        /* webpackChunkName: "asmanenergycostsbeforeref" */ "../forms/anenergycostsbeforeref/anenergycostsbeforeref"
      );
    },
    loading: Loading,
  });

  // private EnergyConsBeforeRefurbishment = Loadable({
  //   loader: () => {
  //     return import(/* webpackChunkName: "energyconsbeforeref" */ "../forms/energyconsbeforeref/enconsbeforeref");
  //   },
  //   loading: Loading,
  // });

  // private EnergyConsAfterRefurbishment = Loadable({
  //   loader: () => {
  //     return import(/* webpackChunkName: "energyconsafterref" */ "../forms/energyconsafterref");
  //   },
  //   loading: Loading,
  // });

  //   private AnEnergyCostsAfterRef = Loadable({
  //     loader: () => {
  //       return import(/* webpackChunkName: "anenergycostsafterref" */ "../forms/anenergycostsafterref");
  //     },
  //     loading: Loading,
  //   });

  private AnEnergyCostsAfterRef = Loadable({
    loader: () => {
      return import(
        /* webpackChunkName: "anenergycostsafterref" */ "../forms/anenergycostsafterref/anenergycostsafterref"
      );
    },
    loading: Loading,
  });

  private FinancialInformation = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "fininfo" */ "../forms/fininfo/fininfo");
    },
    loading: Loading,
  });

  private MarketScenarios = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "marketscenario" */ "../forms/marketscenario");
    },
    loading: Loading,
  });

  private BundleRetrofitMeasures = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "asmbundleretrofitmeasures" */ "../forms/asmbundleretrofitmeasures");
    },
    loading: Loading,
  });

  private BundleRetrofitMeasures2 = Loadable({
    loader: () => {
      return import(
        /* webpackChunkName: "asmbundleretrofitmeasures2" */ "../forms/asmbundleretrofitmeasures/asmbundleretrofitmeasures.main"
      );
    },
    loading: Loading,
  });

  private RentRollDetails = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "rentrolldetails" */ "../forms/rentrolldetails");
    },
    loading: Loading,
  });

  private RentIncDueRefurbishment = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "rentincduerefurbishment" */ "../forms/rentincduerefurbishment");
    },
    loading: Loading,
  });

  private ConsCapitalGains = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "conscapitalgains" */ "../forms/conscapitalgains");
    },
    loading: Loading,
  });

  private Disclaimer = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "disclaimer" */ "../forms/disclaimer");
    },
    loading: Loading,
  });

  private VOFIGrid = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "vofigrid" */ "../forms/vofigrid");
    },
    loading: Loading,
  });

  private KPISInvPersp1 = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "kpisinvpersp1" */ "../forms/kpisinvpersp1");
    },
    loading: Loading,
  });

  private KPISInvPersp2 = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "kpisinvpersp2" */ "../forms/kpisinvpersp2");
    },
    loading: Loading,
  });

  private KPISTenPersp = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "kpitenpersp" */ "../forms/kpitenpersp");
    },
    loading: Loading,
  });

  private KPISEnvRersPersp = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "kpisenvres" */ "../forms/kpisenvres");
    },
    loading: Loading,
  });

  private AddNonMonImpRef = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "addnonmonimpref" */ "../forms/addnonmonimpref");
    },
    loading: Loading,
  });

  private PrintReportConfig = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "printreportconfig" */ "../forms/printreportconfig");
    },
    loading: Loading,
  });

  private DetailedEstimationOfExitYield = Loadable({
    loader: () => {
      return import(
        /* webpackChunkName: "asmdetailedestimationofexityield" */ "../forms/asmdetailedestimationofexityield"
      );
    },
    loading: Loading,
  });

  //   private DetailedConversionFactorsBR = Loadable({
  //     loader: () => {
  //       return import(
  //         /* webpackChunkName: "detailedconversionfactorsbr" */ "../sub/detailedconversionfactorsbr/detconvfactors.before"
  //       );
  //     },
  //     loading: Loading,
  //   });

  //   private DetailedConversionFactorsAF = Loadable({
  //     loader: () => {
  //       return import(/* webpackChunkName: "detailedconversionfactorsaf" */ "../sub/detailedconversionfactorsaf");
  //     },
  //     loading: Loading,
  //   });

  //   private DetailedEnergyPricesBefore = Loadable({
  //     loader: () => {
  //       return import(/* webpackChunkName: "detailedenergypricesbefore" */ "../sub/detailedenergypricesbefore");
  //     },
  //     loading: Loading,
  //   });

  //   private DetailedEnergyPricesAfter = Loadable({
  //     loader: () => {
  //       return import(/* webpackChunkName: "detailedenergypricesafter" */ "../sub/detailedenergypricesafter");
  //     },
  //     loading: Loading,
  //   });

  private Sensitivity = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "sensitivity" */ "../forms/sensitivity/sensitivity");
    },
    loading: Loading,
  });

  private CombEnConsBeforeRef = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "combenconsbeforeref" */ "../forms/combenconsbef/CombEnConsBeforeRef.main");
    },
    loading: Loading,
  });

  private CombEnConsAfterRef = Loadable({
    loader: () => {
      return import(/* webpackChunkName: "combenconsafterref" */ "../forms/combenconsafter/CombEnConsAfterRef.main");
    },
    loading: Loading,
  });

  //

  private routes: any = [];

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  public componentDidUpdate(prevProps: any) {}

  public render() {
    return (
      <div id="main">
        <Location>
          {(loc: any) => {
            return (
              <>
                <Header loc={loc} />
              </>
            );
          }}
        </Location>
        <div id="root">
          <>
            <Location>
              {(loc: any) => {
                return <Sidebar loc={loc} />;
              }}
            </Location>
            <div id="content">
              <Router>
                <this.Screen01 path="/" />
                <this.Screen01 path="intro/1" />
                <this.Screen02 path="intro/2" />
                <this.Screen03 path="intro/3" />
                <this.Screen04 path="intro/4" />
                <this.Video path="video" />
                <this.Selection path="selection" />
                <this.Signup path="signup" />

                <this.Location path="locationOld" />
                <this.Location2 path="location" />

                <this.APropertyDesc path="assisted/propdesc" />
                <this.InvestorCharacteristics path="invchars" />
                {/* <this.InvestorCharacteristics2 path="invchars" /> */}
                <this.PropertyDescription path="propdesc" />
                {/* <this.EnergyConsBeforeRefurbishment path="ecbeforeref" /> */}
                {/* <this.BundleRetrofitMeasures path="assisted/rfmeasures" /> */}

                {/* <this.InvCostsEnergeticRef path="invcostenref" /> */}
                <this.InvCostsEnergeticRef path="invcostenref" />
                <this.ChangeAnnualMaintenanceAfterRef path="chanmainafterref" />
                <this.Depreciation path="depreciation" />
                {/* <this.EnergyConsAfterRefurbishment path="ecafterref" /> */}

                {/* <this.AnEnergyCostsAfterRef path="anecafterref" /> */}
                <this.FinancialInformation path="fininfo" />
                <this.MarketScenarios path="marketscenarios" />
                <this.RentRollDetails path="rentrolldetails" />
                <this.RentIncDueRefurbishment path="rentincdueref" />
                <this.ConsCapitalGains path="conscapitalgains" />
                <this.Disclaimer path="disclaimer" />
                <this.KPISInvPersp1 path="kpiinvpersp1" />
                <this.KPISInvPersp2 path="kpiinvpersp2" />
                <this.VOFIGrid path="vofigrid" />
                <this.KPISTenPersp path="kpitenpersp" />
                <this.KPISEnvRersPersp path="kpisenvrerspersp" />
                <this.AddNonMonImpRef path="addnonmonimpref" />
                <this.PrintReportConfig path="confprintrep" />
                <this.DetailedEstimationOfExitYield path="detailedestimationofexityield" />
                {/* <this.DetailedConversionFactorsBR path="detailedconversionfactorsbr" />
                <this.DetailedConversionFactorsAF path="detailedconversionfactorsaf" /> */}
                {/* <this.DetailedEnergyPricesAfter path="detailedconversionfactorsaf" />
                <this.DetailedEnergyPricesBefore path="sub/denpricesbefore" />
                <this.DetailedEnergyPricesAfter path="sub/denpricesafter" /> */}
                {/*  New */}
                <this.Sensitivity path="sensitivity" />
                <this.CombEnConsBeforeRef path="combensconsbeforeref/*" />
                <this.AsmAnEnergyCostsBeforeRef path="asmanecbeforeref/*" />
                <this.CombEnConsAfterRef path="ecafterref/*" />
                {/* WIP */}
                {/* <this.BundleRetrofitMeasures path="assisted/rfmeasures" /> */}
                <this.BundleRetrofitMeasures2 path="assisted/rfmeasures" />
                <this.AnEnergyCostsAfterRef path="asmanecafterref/*" />
                <NoMatch default={true} />
              </Router>
            </div>
            <div id="sidebarright" className="rightsidebar">
              12
            </div>
          </>
        </div>
        <div id="footer">
          <div id="footerleft">&copy; The RentalCal Consortium 2017-2022</div>
          <div id="footermiddle">
            <div id="modalApp" className="modalbox" />
          </div>
          <div id="footerright">
            <span>Funded by the European Union</span>
            <img src="/assets/flag_europe.svg" id="europeflagxs" alt="Europe Flag" />
          </div>
        </div>
        <Main />
      </div>
    );
  }
}

export default connect<any, any, any, any>(
  (state) => ({
    langitems: state.i18n.translations,
    langcode: state.i18n.lang,
    sbright: state.ui.sbright,
    email: state.EMailAddress,
  }),
  (dispatch: any) => {
    ActionCreators.dispatch = dispatch;
    ActionCreators.setRightSidebar = bindActionCreators(ActionCreators.setRightSidebar, dispatch);
    ActionCreators.setTranslationLanguage = bindActionCreators(ActionCreators.setTranslationLanguage, dispatch);
    return ActionCreators;
  }
)(Root);

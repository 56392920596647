import { connect } from "react-redux";
import React from "react";
import classNames from "classnames";
import * as ActionCreators from "../store";

import Translate from "../translate";

import * as MENUITEMS from "./menu.json";

class SidebarMenu extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      active: null,
      pathname: this.props.loc,
    };
  }

  private onItemClick = (e: any) => {
    console.log("SBPROPS: %o", this.props.loc.location.pathname);
  };

  public render() {
    const items = Object.keys(MENUITEMS).map((item, i) => {
      const key = `mi-${i}`;

      //let childs = [];

      // Process 3 top-level items

      let topActive = -1;
      // TODO
      // if (MENUITEMS[item] instanceof Array) {
      //   // Process child items
      //   childs = MENUITEMS[item].map((_item: any, subKey: string) => {
      //     const _key = `child-${subKey}`;
      //     // const toggle = subKey === this.props.ats;
      //     const toggle =
      //       this.props.loc.location.pathname === _item.url || this.props.loc.location.pathname === _item.ass;
      //     if (toggle) {
      //       topActive = i;
      //     }
      //
      //     if (toggle) {
      //     }
      //
      //     return (
      //       <div className={classNames("l1", { active: toggle })} key={_key}>
      //         {/* {_item.url === this.state.pathname ? "Wooo" : null} */}
      //         <div className="left" data-url={_item.url} onClick={this.onItemClick}>
      //           <Translate id={_item.label} />
      //         </div>
      //         <div className="itemicon" onClick={this.onItemClick}>
      //           <i className={classNames("material-icons")}>arrow_right</i>
      //         </div>
      //       </div>
      //     );
      //   });
      // }

      const isActive = i === topActive;
      topActive = -1;

      return (
        <div key={key}>
          <div
            className={classNames("l0", { active: isActive })}
            onClick={(e) => {
              e.stopPropagation();
              this.onItemClick(e);
            }}
            id={`item${i}`}
            role="Navigation"
          >
            <div className="left">
              <Translate id={item} />
            </div>
            <div className="itemicon">
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
          <div className={classNames("miitems", { subvisible: !isActive })}>NAV</div>
        </div>
      );
    });

    return <div id="tree">{items}</div>;
  }
}

export default connect<any, any, any, any>((state: any) => {
  return {
    path: "",
    atm: state.ui.atm,
    ats: state.ui.ats,
  };
}, ActionCreators)(SidebarMenu);

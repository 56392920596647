import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import classNames from "classnames";
import { ActionCreators } from "../store";
import { Location } from "@reach/router";

// TODO fix broken help display
class HelpTrigger extends React.Component<any, any> {
  public static propTypes = {
    setHelpContent: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    id: PropTypes.string,
    classes: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  };

  constructor(props: any) {
    super(props);
    this.state = {
      isHelpContentLocked: false,
    };
    this.onHelpClick = this.onHelpClick.bind(this);
    this.onHelpMouseOver = this.onHelpMouseOver.bind(this);
    this.clearHelpContent = this.clearHelpContent.bind(this);
  }

  public componentWillUnmount() {
    this.setState({ isHelpContentLocked: false });
    this.clearHelpContent();
  }

  public onHelpClick() {
    this.setState({ isHelpContentLocked: true });
    this.props.dispatch(this.props.setHelpContent({ helptext: this.props.children }));
    this.props.dispatch({ type: "SET_SIDEBARTAB", object: { activetab: 1 } });
  }

  public onHelpMouseOver() {
    this.props.dispatch(this.props.setHelpContent({ helptext: this.props.children }));
  }

  public clearHelpContent() {
    if (!this.state.isHelpContentLocked) {
      this.props.setHelpContent([]);
    }
  }

  public render() {
    return (
      <Location>
        {(props: any) => {
          return (
            <div id={this.props.id} className={classNames("btnhelp", this.props.classes)}>
              <i
                className="material-icons icnhelp"
                aria-hidden="true"
                onClick={this.onHelpClick}
                onFocus={this.onHelpMouseOver}
                onMouseOver={this.onHelpMouseOver}
                onMouseOut={this.clearHelpContent}
                onBlur={this.clearHelpContent}
              >
                help
              </i>
              <div className="helpContent" />
            </div>
          );
        }}
      </Location>
    );
  }
}

export default connect<any, any, any, any>(
  (state) => ({
    activetab: state.ui.activetab,
  }),
  (dispatch: any) => {
    ActionCreators.dispatch = dispatch;
    ActionCreators.setStatus = bindActionCreators(ActionCreators.setStatus, dispatch);
    return ActionCreators;
  }
)(HelpTrigger);

import { combineForms } from "../lib/react-redux-form";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger, { createLogger } from "redux-logger";

let initialState = {};

import { composeWithDevTools } from "redux-devtools-extension";

const SET_HELPCONTENT = "SET_HELPCONTENT";
const SET_SETTINGSDATA = "SET_SETTINGSDATA";
const SET_SIGNUPCONTENT = "SET_SIGNUPCONTENT";
const SET_LOCATIONDATA = "SET_LOCATIONDATA";
const SET_INVTYPEDATA = "SET_LOCATIONDATA";
const SET_UITRANSLATION = "SET_UITRANSLATION";
const SET_MENUDATA = "SET_MENUDATA";
const SET_STATE = "SET_STATE";
const SET_TERMSDATA = "SET_TERMSDATA";
const SET_SIDEBARTAB = "SET_SIDEBARTAB";
const SET_CALCRESULT = "SET_CALCRESULT";
const SET_DISPLAYRIGHTSIDEBAR = "SET_DISPLAYRIGHTSIDEBAR";
const SET_DATA = "SET_DATA";
const SET_STATUS = "SET_STATUS";
const SET_LOCATION = "SET_LOCATION";
const SET_MODE = "SET_MODE";
const SET_ACTIVE_SUB = "SET_ACTIVE_SUB";
export const SET_CHARTLAYOUT = "SET_CHARTLAYOUT";

const SET_CF_BEFORE = "SET_CF_BEFORE";
const SET_CF_AFTER = "SET_CF_AFTER";

// New Actions (mostly related to persisting the XState machines)
const SET_RC2_APROPDESC_MACHINE = "SET_RC2_APROPDESC_MACHINE";
export const UPDATE_ENCONS_AFTERREF_VIA_MEASURES = "UPDATE_ENCONS_AFTERREF_VIA_MEASURES";
export const SET_ASMENCONSBEFOREREF_MACHINE = "SET_ASMENCONSBEFOREREF_MACHINE";

export const SET_CALCSET_SYTEM_DETAILS = "SET_CALCSET_SYTEM_DETAILS";

//
export const STORE_ENCONS_BEFORE = "STORE_ENCONS_BEFORE";
export const STORE_ENCONS_AFTER = "STORE_ENCONS_AFTER";

// Save a copy of the selected country conversion factors
export const SET_COUNTRY_CONVFACTORS = "SET_COUNTRY_CONVFACTORS";

// v2
export const PREFILL_CONV_FACTORS = "PREFILL_CONV_FACTORS";
export const UPDATE_LETTABLE_AREA = "UPDATE_LETTABLE_AREA";
export const INIT_ENCONS_AFTER = "INIT_ENCONS_AFTER";

export const SET_ANENCOSTSBEFOREREF_MACHINE = "SET_ANENCOSTSBEFOREREF_MACHINE";
export const SET_ANENCOSTSAFTERREF_MACHINE = "SET_ANENCOSTSAFTERREF_MACHINE";
export const SET_INVCHARS_MACHINE = "SET_INVCHARS_MACHINE";
export const SET_ABUNDLERETROFITMEASURES_MACHINE = "SET_ABUNDLERETROFITMEASURES_MACHINE";
export const SET_INVCOSTENREF_MACHINE = "SET_INVCOSTENREF_MACHINE";

export const UPDATE_LOCATION_MACHINE = "UPDATE_LOCATION_MACHINE";

// New (01.08.2022)
// Persist the machine state
const MachineReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_RC2_APROPDESC_MACHINE: {
      return {
        ...state,
        asmPropertyDesc: {
          ...action.payload,
        },
      };
    }
    case SET_ASMENCONSBEFOREREF_MACHINE: {
      return {
        ...state,
        asmenconsbeforeref: {
          ...action.payload,
        },
      };
    }
    case SET_INVCHARS_MACHINE: {
      return {
        ...state,
        invchars: {
          ...action.payload,
        },
      };
    }
    case SET_ABUNDLERETROFITMEASURES_MACHINE: {
      return {
        ...state,
        asmbundleretrofitmeasures: {
          ...action.payload,
        },
      };
    }
    case SET_INVCOSTENREF_MACHINE: {
      return {
        ...state,
        invcostsenergeticref: {
          ...action.payload,
        },
      };
    }
    case UPDATE_LOCATION_MACHINE: {
      const newState = {
        ...state,
        location: {
          ...action.payload,
        },
      };
      console.log("UPDATING WITH NEW STATE: %o", newState);
      return newState;
    }
  }
  return state;
};

const UIReducer = (state = {}, action: any) => {
  let tempState = null;
  switch (action.type) {
    case SET_TERMSDATA:
      tempState = Object.assign({}, state, action.object);
      return tempState;
    case SET_HELPCONTENT:
      tempState = Object.assign({}, state, action.object);
      return tempState;
    case SET_SIDEBARTAB:
      tempState = Object.assign({}, state, action.object);
      return tempState;
    case SET_MENUDATA:
      tempState = Object.assign({}, state, action.object);
      return tempState;
    case SET_DISPLAYRIGHTSIDEBAR:
      tempState = Object.assign({}, state, action.object);
      return tempState;
    case SET_LOCATION:
      tempState = Object.assign({}, state, action.object);
      return tempState;
    case SET_MODE:
      tempState = Object.assign({}, state, action.object);
      return tempState;
    case SET_CHARTLAYOUT: {
      const _state = {
        ...state,
        chartlayout: action.layout,
      };
      return _state;
    }
    case SET_STATE:
      // Set state - "empty" / "work"
      tempState = Object.assign({}, state, action.object);
      return tempState;
    default:
      return state;
  }
};

// This is the "global" state reducer for the "v2" version
// Machine enhanced version shoud store their "context" into
// the "v2" state tree (see initialstate.json)
export function V2Reducer(state = initialState, action: any) {
  switch (action.type) {
    case STORE_ENCONS_BEFORE: {
      const _state = {
        ...state,
        enconsbeforeref: {
          ...action.payload,
        },
      };
      return _state;
    }
    /*
    This action gets called in "Bundle of retrofit measures"
    It pre-populates the AsmEnConsAfterRef EC values.
    We save it in 2 (enconsafterrefdata + enconsafterref) places
    for the "reset" functionality
     */
    case INIT_ENCONS_AFTER: {
      const _state = {
        ...state,
        enconsafterrefdata: {
          ...action.payload,
        },
      };
      return _state;
    }
    case STORE_ENCONS_AFTER: {
      const _state = {
        ...state,
        enconsafterref: {
          ...action.payload,
        },
      };
      return _state;
    }
    case PREFILL_CONV_FACTORS: {
      const _state = {
        ...state,
        enconsbeforeref: {
          ...(state as any).enconsbeforeref,
          ...action.payload,
        },
      };
      return _state;
    }
    case UPDATE_LETTABLE_AREA: {
      const _state = {
        ...state,
        enconsbeforeref: {
          ...(state as any).enconsbeforeref,
          ...action.payload,
        },
      };
      return _state;
    }
    case SET_ANENCOSTSBEFOREREF_MACHINE: {
      return {
        ...state,
        anenpricesbeforeref: {
          ...action.payload,
        },
      };
    }
    case SET_ANENCOSTSAFTERREF_MACHINE: {
      return {
        ...state,
        anenpricesafterref: {
          ...action.payload,
        },
      };
    }
    default: {
      break;
    }
  }
  return state;
}

const SubStateReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ACTIVE_SUB: {
      const newState = { ...state, ...action.object };
      return newState;
    }
    default: {
      return state;
    }
  }
};

const I18NReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_UITRANSLATION: {
      const newState = { ...state, ...action.object };
      return newState;
    }
    default: {
      return state;
    }
  }
};

const DataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_DATA: {
      const tempState = { ...state, ...action.object };
      return tempState;
    }
    case SET_COUNTRY_CONVFACTORS: {
      return {
        ...state,
        countryconversionfactors: {
          ...action.payload,
        },
      };
    }
    case SET_CALCSET_SYTEM_DETAILS: {
      return {
        ...state,
        calcsetSystemDetails: {
          ...action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

const CalcResultReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CALCRESULT: {
      const tempState = { ...state, ...action.object };
      return tempState;
    }
    default: {
      return state;
    }
  }
};

const StatusReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_STATUS: {
      const tempState = { ...state, ...action.object };
      return tempState;
    }
    default: {
      return state;
    }
  }
};

/* Submodule reducer */

const setCFBefore = (data: any) => {
  return {
    type: SET_CF_BEFORE,
    payload: {
      data,
    },
  };
};

const setCFAfter = (data: any) => {
  return {
    type: SET_CF_AFTER,
    payload: {
      data,
    },
  };
};

// Handles the "cf" node (Submodule)
const SubReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CF_BEFORE: {
      const tempState: any = { ...state };
      tempState.before = {
        ...action.payload.value,
      };
      return tempState;
    }
    case SET_CF_AFTER: {
      const tempState: any = { ...state };
      tempState.after = action.payload;
      return tempState;
    }
    default: {
      return state;
    }
  }
};

function getStore(loadedState: any, callBack: any): any {
  initialState = loadedState;

  const reducerList = combineReducers({
    ui: UIReducer,
    i18n: I18NReducer,
    calcResult: CalcResultReducer,
    substate: SubStateReducer,
    data: DataReducer,
    status: StatusReducer,
    // New jca - 01.08.2022
    machines: MachineReducer,
    /* Conversion factors */
    cf: SubReducer,
    v2: V2Reducer,
    pages: combineForms(
      {
        ProjectData: loadedState.pages.ProjectData,
        AssModePropertyDesc: loadedState.pages.AssModePropertyDesc,
        BundleRetrofitMeasures: loadedState.pages.BundleRetrofitMeasures,
        Location: loadedState.pages.Location,
        InvestorCharacteristics: loadedState.pages.InvestorCharacteristics,
        PropertyDescription: loadedState.pages.PropertyDescription,
        EnConsBeforeRef: loadedState.pages.EnConsBeforeRef,
        AnEnergyCostsBeforeRef: loadedState.pages.AnEnergyCostsBeforeRef,
        InvCostsEnergeticRef: loadedState.pages.InvCostsEnergeticRef,
        DetailedConvFactorsBefore: loadedState.pages.DetailedConvFactorsBefore,
        DetailedConvFactorsAfter: loadedState.pages.DetailedConvFactorsAfter,
        ChangeAnnualMaintenanceAfterRef: loadedState.pages.ChangeAnnualMaintenanceAfterRef,
        Depreciation: loadedState.pages.Depreciation,
        EnConsAfterRef: loadedState.pages.EnConsAfterRef,
        AnEnergyCostsAfterRef: loadedState.pages.AnEnergyCostsAfterRef,
        FinancialInformation: loadedState.pages.FinancialInformation,
        MarketScenarios: loadedState.pages.MarketScenarios,
        RentRollDetails: loadedState.pages.RentRollDetails,

        RentIncDueRefurbishment: loadedState.pages.RentIncDueRefurbishment,

        ConsCapitalGains: loadedState.pages.ConsCapitalGains,

        DetailedEstimationOfExitYield: loadedState.pages.DetailedEstimationOfExitYield,
        KPIInvPersp1: loadedState.pages.KPIInvPersp1,
        KPIInvPersp2: loadedState.pages.KPIInvPersp2,
        KPITenPersp: loadedState.pages.KPITenPersp,
        KPIEnvResPersp: loadedState.pages.KPIEnvResPersp,
        DetailedEnergyPricesBefore: loadedState.pages.DetailedEnergyPricesBefore,
        DetailedEnergyPricesAfter: loadedState.pages.DetailedEnergyPricesAfter,
      },
      "pages"
    ),
  });

  const hof = (reducers: any, _initialState: any): any => {
    const higherState = {
      state: _initialState,
    };
    return (state = {}, action: any) => {
      switch (action.type) {
        case "REPLACE_WHOLE_STATE":
          higherState.state = action.data;
          return higherState.state;
        default:
          return reducers(state, action);
      }
    };
  };

  const composeEnhancers = compose;

  const persistConfig = {
    key: "root",
    keyPrefix: "",
    storage,
    blacklist: ["i18n", "forms"],
    debug: true,
  };

  const persistedReducer = persistReducer(persistConfig, reducerList);

  //
  // Redux Logger
  //
  const middlewares = [];
  if (process.env.NODE_ENV === `development`) {
    const reduxLogger = createLogger({
      collapsed: true,
    });

    middlewares.push(reduxLogger);
  }

  const store = createStore(
    // reducerList,
    persistedReducer,
    loadedState,
    composeWithDevTools(applyMiddleware(thunk, ...middlewares))
  );

  const persistor = persistStore(store, undefined, callBack);

  return { store, persistor };
  // return store;
}

function setData(object: any) {
  return {
    type: SET_DATA,
    object,
  };
}

function setMode(object: any) {
  return {
    type: SET_MODE,
    object,
  };
}

function setHelpContent(object: any) {
  return {
    type: SET_HELPCONTENT,
    object,
  };
}

function setSignupContent(object: any) {
  return {
    type: SET_SIGNUPCONTENT,
    object,
  };
}

function setLocationData(object: any) {
  return {
    type: SET_LOCATIONDATA,
    object,
  };
}

function setInvTypeData(object: any) {
  return {
    type: SET_INVTYPEDATA,
    object,
  };
}

function setMenuData(object: any) {
  return {
    type: SET_MENUDATA,
    object,
  };
}

function setTermsData(object: any) {
  return {
    type: SET_TERMSDATA,
    object,
  };
}

function setSettingsData(object: any) {
  return {
    type: SET_SETTINGSDATA,
    object,
  };
}

function setTranslationLanguage(object: any) {
  return {
    type: SET_UITRANSLATION,
    object,
  };
}

function setRightSidebar(object: any) {
  return {
    type: SET_DISPLAYRIGHTSIDEBAR,
    object,
  };
}

function setCalcResult(object: any) {
  return {
    type: SET_CALCRESULT,
    object,
  };
}

function setStatus(object: any) {
  return {
    type: SET_STATUS,
    object,
  };
}

function setLocation(object: any) {
  return {
    type: SET_LOCATION,
    object,
  };
}

function setSubState(object: any) {
  return {
    type: SET_ACTIVE_SUB,
    object,
  };
}

export const members = {
  SET_HELPCONTENT,
  SET_SETTINGSDATA,
  SET_SIGNUPCONTENT,
  SET_LOCATIONDATA,
  SET_INVTYPEDATA,
  SET_UITRANSLATION,
  SET_MENUDATA,
  SET_TERMSDATA,
  SET_CALCRESULT,
  SET_DISPLAYRIGHTSIDEBAR,
  SET_DATA,
  SET_MODE,
  SET_RC2_APROPDESC_MACHINE,
  SET_CF_BEFORE,
};

export const ActionCreators = {
  setHelpContent,
  setSignupContent,
  setLocationData,
  setInvTypeData,
  setMenuData,
  setTermsData,
  setSettingsData,
  setTranslationLanguage,
  setCalcResult,
  setRightSidebar,
  setData,
  setStatus,
  setLocation,
  setMode,
  setSubState,
  setCFBefore,
  setCFAfter,
  dispatch: null,
};

export default getStore;

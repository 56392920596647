import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { has as _has } from "lodash-es";
import Translate from "../translate";

export default function HelpTab(props: any) {
  const helpText = useSelector((state: any) => {
    if (_has(state, "ui.helptext.props.id")) {
      return state.ui.helptext.props.id;
    } else {
      return null;
    }
  });
  const activeTab = useSelector((state: any) => state.ui.activetab);

  return (
    <div className={classNames("tab-pane", { tabactive: activeTab === 1 })} id="help" role="tabpanel">
      <Translate id={helpText} />
    </div>
  );
}

import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import HelpTab from "./helptab";
import Progress from "./progress";
import Translate from "../translate";

class Sidebar extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};

    this.onTabSelect = this.onTabSelect.bind(this);
  }

  public onTabSelect(e: any) {
    e.preventDefault();

    if (e.currentTarget.id === "tab1") {
      this.props.dispatch({ type: "SET_SIDEBARTAB", object: { activetab: 0 } });
    } else {
      this.props.dispatch({ type: "SET_SIDEBARTAB", object: { activetab: 1 } });
    }
  }

  public render() {
    return (
      <div id="sidebarleft">
        <ul className="rctabs">
          <li
            className={classNames({ active: this.props.activetab === 0 })}
            id="tab1"
            onClick={this.onTabSelect}
            style={{ paddingLeft: "18px" }}
          >
            <Translate id="sidebar.tabheader.whereami" />
          </li>
          <li className={classNames({ active: this.props.activetab === 1 })} id="tab2" onClick={this.onTabSelect}>
            <Translate id="sidebar.tabheader.help" />
          </li>
        </ul>
        {process.env.NODE_ENV !== "production" && <Progress width={160} height={160} {...this.props} />}
        <HelpTab />
      </div>
    );
  }
}

export default connect<any, any, any, any>(
  (state) => {
    return {
      activetab: state.ui.activetab,
    };
  },
  (dispatch: any) => {
    return {
      dispatch,
    };
  }
)(Sidebar);

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import INITIAL_STATE from "./initialstate.json";
import INITIAL_LANG from "./i18n/locale_en.json";
import getStore from "./store";

// Font
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// Styles
import "./style/bootstrap.min.css";
import "./style/main.scss";
import "./style/navigation.scss";
import "./style/slider.scss";

import "react-select/dist/react-select.css";

// Progress
import "react-circular-progressbar/dist/styles.css";

import preval from "preval.macro";

const buildInfo = preval`
    const os = require("os");
    module.exports = {hostname: os.hostname(), date: new Date()};
`;

const BANNER = `
 ______               __          __ ______         __
|   __ \.-----.-----.|  |_.---.-.|  |      |.---.-.|  |
|      <|  -__|     ||   _|  _  ||  |   ---||  _  ||  |
|___|__||_____|__|__||____|___._||__|______||___._||__| v2.0
Build Date: ${buildInfo.date}
Build Host: ${buildInfo.hostname}
`;

console.log(BANNER);

const root = ReactDOM.createRoot(document.getElementById("app") as HTMLElement);

//console.log("ENV: %o",process.env.REACT_APP_GQL_HOST);

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

//////////////////////

// const RCProvider = (props: any) => {
//   return <I18NContext.Provider {...props}>{props.children}</I18NContext.Provider>;
// };

//////////////////////

// async function loadData() {
//   return fetch(Settings.getDataURLFor("initialstate.json"))
// }
//

const boot = (initialState = INITIAL_STATE, initialLanguage = INITIAL_LANG) => {
  const state: any = {
    ...initialState,
    i18n: {
      ...initialLanguage,
    },
  };

  const { store, persistor } = getStore(state, () => {});

  root.render(<App persistor={persistor} store={store} translations={initialLanguage} />);
};

boot();

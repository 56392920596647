import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import SidebarMenu from "./sidebarmenu";
import { ActionCreators } from "../store";
import Translate from "../translate";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

class Progress extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  public onClick(e: any) {
    e.preventDefault();
  }

  public render() {
    const percentage = 64;
    return (
      <div className={classNames("tab-pane", { tabactive: this.props.activetab === 0 })} id="wherepanel">
        <div id="statustree">
          <SidebarMenu {...this.props} />
        </div>
        <div
          className="d-flex flex-row justify-content-center"
          style={{ backgroundColor: "#f0f0f0", borderTop: "1px solid #e0e0e0" }}
        >
          <div style={{ width: 120, height: 120, padding: "15px" }}>
            <CircularProgressbar
              value={percentage}
              text={`${percentage}%`}
              strokeWidth={12}
              styles={buildStyles({
                textSize: "16px",
                strokeLinecap: "butt",
                pathTransitionDuration: 0.5,
                pathColor: "#9daebc",
                textColor: "#c0c0c0",
                trailColor: "#c8ced7",
              })}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect<any, any, any, any>(
  (state) => ({
    langitems: state.i18n.translations,
    langcode: state.i18n.lang,
    activetab: state.ui.activetab,
  }),
  ActionCreators
)(Progress);

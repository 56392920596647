import "whatwg-fetch";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toPairs } from "lodash";

import { ActionCreators } from "../store";
import Settings from "../settings";
import Translate from "../translate";

/**
 * Handles the language selection
 */
class CPreferences extends React.Component<any, any> {
  constructor(props: any, context: any) {
    super(props, context);

    this.loadLanguage = this.loadLanguage.bind(this);
    this.generateLanguageOptions = this.generateLanguageOptions.bind(this);

    this.state = {
      languageSelection: {
        de: {
          en: "Englisch",
          de: "Deutsch",
          fr: "Französisch",
          pl: "Polnisch",
          dk: "Dänisch",
          es: "Spanisch",
          cz: "Tschechisch"
        },
        en: {
          en: "English",
          de: "German",
          fr: "French",
          pl: "Polish",
          dk: "Danish",
          es: "Spanish",
          cz: "Czech"
        },
        fr: {
          en: "Anglais",
          de: "Allemand",
          fr: "Français",
          pl: "Polonais",
          dk: "Danois",
          es: "Espagnol",
          cz: "Tchèque"
        },
        pl: {
          en: "Angielski",
          de: "Niemiecki",
          fr: "Francuski",
          pl: "Polski",
          dk: "Duński",
          es: "Hiszpański",
          cz: "Czech"
        },
        dk: {
          en: "Engelsk",
          de: "Tysk",
          fr: "Fransk",
          pl: "Polsk",
          dk: "Dansk",
          es: "Spansk",
          cz: "Tjekkisk"
        },
        es: {
          en: "Inglés",
          de: "Alemán",
          fr: "Francés",
          pl: "Polaco",
          dk: "Danés",
          es: "Español",
          cz: "Checo"
        },
        cz: {
          en: "Angličtina",
          de: "Němec",
          fr: "Francouzský",
          pl: "Polský",
          dk: "Dánský",
          es: "Španělský",
          cz: "Český"
        }
      }
    };
  }

  public loadLanguage = (codeCountry = "en") => {
    // Fetch translation data
    const url = Settings.getLocaleURLFor(codeCountry);
    // eslint-disable-next-line
    fetch(url)
      .then(response => response.json())
      .then(json => {
        // Overlay the english translations
        const transObj = {
          lang: codeCountry,
          translations: {
            // ...this.props.entrans.translations,
            ...json.translations
          }
        };
        this.props.setTranslationLanguage(transObj);
      });
  };

  public generateLanguageOptions() {
    const options = toPairs(this.state.languageSelection[this.props.langcode]).map((item: any) => (
      <option key={`lang-${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
    return options;
  }

  public render() {
    return (
      <form noValidate={true}>
        <div
          className={this.props.classes}
          id="navbarToggleExternalContent"
          style={{ borderBottom: "1px solid #e0e0e0" }}
        >
          <div className="bg p-3">
            <h5>
              <Translate id="header.preferences.headline" />
            </h5>
            <div className="form-group">
              <label htmlFor="formGroupExampleInput">
                <Translate id="header.preferences.select.language" />
              </label>
              <select
                className="form-control col-sm-2"
                id="cbxLanguage"
                onChange={ref => this.loadLanguage(ref.currentTarget.value)}
                value={this.props.langcode}
              >
                {this.generateLanguageOptions()}
              </select>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default connect<any, any, any, any>(
  state => ({
    langcode: state.i18n.lang,
    // translation: state.i18n,
    entrans: state.data.langcache
  }),
  (dispatch: any) => {
    ActionCreators.dispatch = dispatch;
    ActionCreators.setRightSidebar = bindActionCreators(ActionCreators.setRightSidebar, dispatch);
    ActionCreators.setTranslationLanguage = bindActionCreators(ActionCreators.setTranslationLanguage, dispatch);
    return ActionCreators;
  }
)(CPreferences);
